var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật đối tượng",
        visible: _vm.dialogVisible,
        width: "700px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "requestData",
          attrs: {
            "label-width": "120px",
            model: _vm.requestData,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên đối tượng", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên đối tượng" },
                model: {
                  value: _vm.requestData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.requestData, "name", $$v)
                  },
                  expression: "requestData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả", prop: "description" } },
            [
              _c("el-input", {
                attrs: { rows: 4, type: "textarea", placeholder: "Nhập mô tả" },
                model: {
                  value: _vm.requestData.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.requestData, "description", $$v)
                  },
                  expression: "requestData.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("requestData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("requestData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }